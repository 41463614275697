 @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .zoom-in-out {
    animation: zoomInOut 2s infinite; 
  }
  @keyframes borderMove {
    0% {
      transform: translate(-50%, -50%) scale(1);
    }
    25% {
      transform: translate(-50%, -50%) scale(1.1);
    }
    50% {
      transform: translate(-50%, -50%) scale(1);
    }
    75% {
      transform: translate(-50%, -50%) scale(1.1);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  
  .border-moving-border {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border: 4px solid transparent;
    border-image: linear-gradient(45deg, #ff0000, #00ff00, #0000ff) 30s infinite;
    border-image-slice: 1;
    animation: borderMove 5s linear infinite;
    z-index: 1;
  }
  